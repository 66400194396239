/* eslint-disable prettier/prettier */
import React from 'react';
import { useAccountGroups } from '@vp/digital-paid-lib';
import { Link, Typography, IconTileIcon } from '@vp/swan';

type GoToDashboardLinkProps = {
  title: string;
  mediaLink: string;
  workspaceUrl: string;
};

export const GoToDashboardLink = ({
  title,
  mediaLink,
  workspaceUrl,
}: GoToDashboardLinkProps) => {
  const { loading, data } = useAccountGroups();
  if (!loading && data?.accountGroups?.length > 0) {
    return (
      <aside className="digital-marketing-page__go-to-my-dashboard-link">
        <IconTileIcon className="digital-marketing-page__go-to-my-dashboard-link__icon">
          <img src={mediaLink} width="36" height="36" alt="" />
        </IconTileIcon>
        <Typography textColor="white">
          <Link href={workspaceUrl} skin="cta">
            {title}
          </Link>
        </Typography>
      </aside>
    );
  }
  return null;
};
