/* eslint-disable prettier/prettier */
import React from 'react';
import { Typography, Box, Link, Button, Icon } from '@vp/swan';
import './ProductGroup.scss';

export type ProductGroupProps = {
  title: string;
  products: Array<{
    name: string;
    ctaLink: string;
  }>;
};

export const ProductGroup = ({ title, products }: ProductGroupProps) => (
  <Box>
    <Typography
      fontSize={2}
      component="h2"
      align="left"
      weight="bold"
      paddingTop={2}
      paddingBottom={2}
    >
      {title}
    </Typography>
    <Box mt={2} mb={3}>
      {products.map((product) => (
        <Link href={product.ctaLink}>
          <Button
            key={product.name}
            skin="secondary"
            size="mini"
            width="standard"
            iconPosition="right"
          >
            {product.name}
            <Icon iconType="chevronRight" />
          </Button>
        </Link>
      ))}
    </Box>
  </Box>
);
