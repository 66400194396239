import { WEBSITES_MPV_ID } from '@vp/digital-product-availability-lib';
import {
  ProductIdentifierModel,
  ProductModel,
} from '@vp/digital-common-data-model-lib';

export interface PricingDataModel {
  productIdentifier: ProductIdentifierModel;
  displayBillingPeriodKey: string;
}

export function buildPricingData(
  product: ProductModel,
  productVersion: number
): PricingDataModel {
  if (product.mvpId.value === WEBSITES_MPV_ID) {
    //Note using this product identifier is wrong, should be coming from metadata serivice
    const productIdentifier = {
      productKey: product.productKey.value,
      productVersion,
      productAttributes: [
        { key: 'Service Length', value: '1 Month' },
        { key: 'Package Level', value: 'Starter' },
      ],
    };
    const displayBillingPeriodKey = '1 Month';
    return { productIdentifier, displayBillingPeriodKey };
  }

  const productIdentifier = {
    productKey: product.productKey.value,
    productVersion,
    productAttributes: [{ key: 'Service Length', value: '1 Year' }],
  };
  const displayBillingPeriodKey = '1 Year';
  return { productIdentifier, displayBillingPeriodKey };
}
