import React from 'react';

import {
  CategoryProductSectionWithImage,
  CategoryProductSectionWithImageList,
  CategoryProductSectionWithTextList,
  CategoryProductDataModel,
} from '@vp/digital-category-product-lib';

export const buildCategoryProductComponent = (
  model: CategoryProductDataModel,
  index: number
) => {
  const isLeft: boolean = !(index % 2);
  // Domain Names
  if (model.metaData) {
    return (
      <CategoryProductSectionWithTextList
        content={model}
        useTextLeft={isLeft}
      />
    );
  }

  // Listing Manager
  if (model.icons) {
    return (
      <CategoryProductSectionWithImageList
        content={model}
        useTextLeft={isLeft}
      />
    );
  }

  // Website builder
  if (model.image) {
    return (
      <CategoryProductSectionWithImage content={model} useTextLeft={isLeft} />
    );
  }
  return null;
};
