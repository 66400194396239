import React from 'react';
import DigitalMarketingPage from '../components/digitalMarketingPage/DigitalMarketingPage';
import { DigitalMarketingPageContextModel } from '../models/digitalMarketing/DigitalMarketingPageContext.models';

const DigitalMarketingPageTemplate = ({
  pageContext,
}: DigitalMarketingPageContextModel) => {
  // const pageData = data.allContentfulComponentPage.edges[0].node;
  return <DigitalMarketingPage pageContext={pageContext} />;
};

export default DigitalMarketingPageTemplate;
