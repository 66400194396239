import {
  trackPageViewed,
  analyticsDataRegistry,
  getTrackingConfiguration,
  TrackingConfiguration,
} from '@vp/digital-tracking-lib';

const { category } = analyticsDataRegistry;

export const trackingAssetName = category.trackingAssetName;

export function trackDigitalMarketingPageLoad(): void {
  trackPageViewed(category);
}

export const trackingConfiguration: TrackingConfiguration =
  getTrackingConfiguration(category);
