/* eslint-disable prettier/prettier */
import React, { useState, useEffect } from 'react';
import { useConfiguredRollbar } from '@vp/digital-rollbar-lib';

import { HeroModel } from '@vp/digital-common-data-model-lib';
import {
  HeroBackground,
  HeroContainer,
  HeroContentContainer,
  HeroDescription,
  HeroInfoContainer,
  HeroLeftContentContainer,
  HeroTitle,
} from '@vp/digital-hero-lib';
import {
  StandardHero,
  StandardHeroCTA,
  GridContainer,
  Column,
  Row,
  BoundedContent,
  Hidden,
} from '@vp/swan';
import { TokenReplacer } from '@vp/digital-token-replacer-lib';
import { FeaturesContainer } from '@vp/digital-features-lib';
import { FaqContainer } from '@vp/digital-faq-lib';
import { GoToDashboardLink } from '../goToDashboardLink/GoToDashboardLink';
import { PricingContainer } from '@vp/digital-pricing-lib';
import {
  WEBSITES_MPV_ID,
  DOMAINS_MPV_ID,
} from '@vp/digital-product-availability-lib';
import { SEOContainer } from '@vp/digital-seo-lib';

import Redirect from '../redirect/Redirect';
import { ProductGroup } from '../productGroup/ProductGroup';
import { CategoryProductDataModel } from '@vp/digital-category-product-lib';
import { DigitalMarketingPageDataModel } from '../../models/digitalMarketing/DigitalMarketingPageData.model';
import { buildCategoryProductComponent } from '../../builders/categoryProductBuilder';
import { buildPricingData } from '../../builders/pricingDataBuilder';
import { DigitalMarketingPageContextModel } from '../../models/digitalMarketing/DigitalMarketingPageContext.models';
import { initTrackingWithKeys } from '@vp/digital-tracking-lib';
import {
  trackDigitalMarketingPageLoad,
  trackingAssetName,
} from './DigitalMarketingPageAnalytics';

import '@vp/digital-category-product-lib/dist/index.css';
import '@vp/digital-hero-lib/dist/index.css';
import '@vp/digital-pricing-lib/dist/index.css';
import '@vp/digital-faq-lib/dist/index.css';
import '@vp/digital-features-lib/dist/index.css';

import './DigitalMarketingPage.scss';

const DOMAINS_FLOW_BASE_QUERY_PARAMS =
  '?stepId=DomainSelection&flowType=Domains';

const DigitalMarketingPage = ({
  pageContext,
}: DigitalMarketingPageContextModel) => {
  useConfiguredRollbar();
  const {
    localizedUrls: { websitesUrl, domainSetupUrl, workspaceUrl }
  } = pageContext;

  // this page is retired and not needed using a sample value to avoid error
  // in case we want to enable it again: 
  // call merchandising-site-experience-lib to get the Mpv object that contains the right version
  const productVersion = 1;
  useEffect(() => {
    initTrackingWithKeys(
      pageContext.locale,
      pageContext.trackingKeys,
      trackingAssetName
    );
    trackDigitalMarketingPageLoad();
  }, [pageContext.locale, pageContext.trackingKeys]);

  const [redirectPath, setRedirectPath] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const getProductPageUrl = (mpvId: string, urls: Array<any>) => {
    return urls.find((url) => url?.mpvId === mpvId)?.url;
  };
  const sectionLink = (mpvId?: string): string => {
    let pageUrl;
    switch (mpvId) {
      case WEBSITES_MPV_ID:
        pageUrl = websitesUrl;
        break;
      default:
        pageUrl = getProductPageUrl(mpvId, pageContext.productPageUrls);
        break;
    }
    return pageUrl;
  };
  const sectionCallback = (mpvId?: string): (() => void) => {
    let callback;
    switch (mpvId) {
      case DOMAINS_MPV_ID:
        callback = (searchedDomain?: string) => {
          let domainLink = `${domainSetupUrl}${DOMAINS_FLOW_BASE_QUERY_PARAMS}`;
          if (searchedDomain) {
            domainLink = `${domainLink}&searchedDomain=${searchedDomain}`;
          }
          setIsRedirecting(true);
          setRedirectPath(domainLink);
        };
        break;
    }
    return callback;
  };

  const categoryPageData =
    pageContext.pageData as DigitalMarketingPageDataModel;
  const productGroups = categoryPageData.hero.productGroups.map((p) => ({
    ...p,
    products: p.products.map((product) => ({
      ...product,
      ctaLink: sectionLink(product.mvpId.value),
    })),
  }));

  const renderProducts: Array<CategoryProductDataModel> =
    categoryPageData.availableProducts.map((availableProduct: HeroModel) => {
      const pricingData = buildPricingData(availableProduct.product, productVersion);
      const model: CategoryProductDataModel = {
        title: availableProduct.title,
        description: (
          <TokenReplacer
            template={availableProduct.description}
            replacements={{
              price: (
                <b>
                  <PricingContainer
                    displayPeriodKey={pricingData.displayBillingPeriodKey}
                    productIdentifier={pricingData.productIdentifier}
                    quantity={1}
                  />
                </b>
              ),
            }}
            convertMarkdown
          />
        ),
        featureGroup: availableProduct.heroFeatureGroup,
        footer: availableProduct.footer,
        ctaName: availableProduct.ctaName,
        ctaDescription: availableProduct.ctaDescription,
        ctaLink: sectionLink(availableProduct.product.mvpId.value),
        ctaCallback: sectionCallback(availableProduct.product.mvpId.value),
        secondaryCtaName: availableProduct.secondaryCtaName,
        secondaryCtaDescription: availableProduct.secondaryCtaDescription,
        secondaryCtaLink: getProductPageUrl(
          availableProduct.product.mvpId.value,
          pageContext.productPageUrls
        ),
      };

      if (availableProduct.heroMetadata) {
        model.metaData = availableProduct.heroMetadata;
      }
      if (availableProduct.icons) {
        model.icons = availableProduct.icons;
      }
      if (availableProduct.backgroundImage) {
        model.image = availableProduct.backgroundImage;
      }

      return model;
    });

  return (
    <Redirect redirectPath={redirectPath} isRedirecting={isRedirecting}>
      <>
        <SEOContainer content={categoryPageData.seo} />
        <article>
          {/* hero section */}

          <HeroContainer>
            <StandardHero
              fullWidthImage
              className="digital-marketing-page__hero__container"
            >
              <Hidden
                sm
                xs
                className="digital-marketing-page__hero__image"
                style={{
                  backgroundImage: `url(${categoryPageData.hero.media[0].media.secureUrl})`,
                }}
              />

              <HeroContentContainer>
                <HeroLeftContentContainer fullWidth>
                  <HeroInfoContainer isWhiteText>
                    <HeroTitle title={categoryPageData.hero.name} />
                    <HeroDescription
                      description={categoryPageData.hero.description}
                    />
                    <StandardHeroCTA>
                      {productGroups.map(
                        (productGroup) =>
                          !!productGroup.products.length && (
                            <ProductGroup
                              key={productGroup.title}
                              title={productGroup.title}
                              products={productGroup.products}
                            />
                          )
                      )}
                    </StandardHeroCTA>
                  </HeroInfoContainer>
                </HeroLeftContentContainer>
              </HeroContentContainer>

              <HeroBackground backgroundClassName="digital-marketing-page__background" />
            </StandardHero>
          </HeroContainer>
        </article>
        <GoToDashboardLink
          title={categoryPageData.goToDashboard.title}
          mediaLink={categoryPageData.goToDashboard.mediaUrl}
          workspaceUrl={workspaceUrl}
        />
        <article className="digital-marketing-page__products-container">
          {/* Products */}
          {renderProducts.map((product, index) => (
            <article
              className="digital-marketing-page__products"
              key={product.title}
            >
              <BoundedContent>
                {buildCategoryProductComponent(product, index)}
              </BoundedContent>
            </article>
          ))}
          {/* FAQs section */}
          <article>
            <BoundedContent>
              <GridContainer>
                <Row>
                  <Column
                    span={12}
                    offsetMd={2}
                    spanMd={8}
                    offsetLg={2}
                    spanLg={8}
                    offsetXl={2}
                    spanXl={8}
                  >
                    <FaqContainer content={categoryPageData.faqs} />
                  </Column>
                </Row>
              </GridContainer>
            </BoundedContent>
          </article>
          {/* Testimonials section */}
          <article>
            <BoundedContent className="digital-marketing-page__testimonials-section">
              <FeaturesContainer
                className="digital-marketing-page__testimonials__testimonial-item"
                headerCentered
                reverseContent
                defaultNumberOfColumns={3}
                content={categoryPageData.testimonials}
                titleSize={5}
                descriptionSize={1}
                nameSize={3}
              />
            </BoundedContent>
          </article>
        </article>
      </>
    </Redirect>
  );
};

export default DigitalMarketingPage;
